#experience {
    overflow: hidden;
    width: 100vw;
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
}

.expSectionTitle {
    font-size: 3rem;
    font-weight: 600;
}

.experienceSection {
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.expSection {
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: rgb(50, 50, 50);
}

.expHeading {
    margin-bottom: 1rem;
}

.expExtra {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    font-weight: 300;
}

.expDesc {
    font-weight: 300;
}

.expDescList {
    text-align: left;
    margin-left: 1rem;
    font-weight: 300;
}

@media screen and (max-width:720px) {
    .expSection{
        font-size: 2vw;
    }
    .expSectionTitle {
        font-size: 2.5rem;
    }
    .expExtra{
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
}

@media screen and (max-width:480px) {
    .expSection {
        font-size: 3vw;
    }
    .expSection > h1{
        font-size: 5vw;
    }
    .expExtra{
        flex-direction: column;
        justify-content: left;
    }
    .expSectionTitle {
        font-size: 2rem;
    }
    .expDescList {
        margin-left: 0.5rem;
    }
}