.navbar {
    height: 5rem;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: rgb(40,40,40);
}

.logo {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
}

.desktopMenuListItems {
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItems:hover {
    color: rgb(100, 181, 246);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(100, 181, 246);
}

.resumeButton {
    object-fit: cover;
    height: 3rem;
    width: 8rem;
    margin: 1rem;
    border: none;
    color: black;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 2rem;
}

.resumeButton:hover {
    cursor: pointer;
    background: rgb(100, 181, 246);
}

.resumeButtonImg {
    object-fit: cover;
    height: 1rem;
    width: 1rem;
    margin: 1rem;
}

.active {
    color: rgb(100, 181, 246);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(100, 181, 246);
}

.mobileMenu {
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    height: fit-content;
    min-width: 15rem;
    background-color: rgb(30,30,30);
    border-radius: 1rem;
}

.mobileMenuListItems{
    color: white;
    padding: 0.5rem 3rem;
    margin: 0.25rem;
    border-radius: 0.25rem;
    background: rgb(25, 25, 25);
}

.mobMenu {
    display: none;
    object-fit: cover;
    height: 3rem;
}

@media screen and (max-width:720px){
    .mobMenu{
        display: flex;
    }
    .desktopMenu{
        display: none;
    }
}